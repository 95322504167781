import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer point_color_bg">
      <div>
        <div>
          <div>
            <img src="" alt="" />
          </div>

          <div>
            <span className="footer_bold_text">{t(`footer.companyName`)}</span>
            <span>{t(`footer.companyAddress`)}</span>

            <span>|</span>
            <span className="footer_bold_text">{t(`footer.ceo`)}</span>
            <span>{t(`footer.ceoName`)}</span>

            <span>|</span>
            <span className="footer_bold_text">
              {t(`footer.businessRegistration`)}
            </span>
          </div>

          <div>
            <span className="footer_bold_text">TEL</span>
            <span>02-2038-8565</span>

            <span>|</span>
            <span className="footer_bold_text">Email</span>
            <span>help@orfeocultures.com</span>
          </div>

          <p>
            Copyright &#169; 2023. {t(`footer.companyName`)}. All rights
            reserved.
          </p>
        </div>

        <div>
          <div></div>
          <div>
            <span>02-2038-8565</span>
            <span>{t(`footer.companyInfo`)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
